@import "variables"
.wrapper
  margin: 0px 20px
.square-small
  width: 32px
  height: 32px
.square-medium
  width: 40px
  height: 40px

.text-logo
  font-weight: bold
  font-size: 1.1rem
  line-height: 32px
  text-align: center
  color: $primaryColor
  letter-spacing: 2px
  margin-left: 4px
.text-description
  font-size: 12px
  line-height: 150%
  color: rgb(128, 136, 156)
  text-align: center
  max-width: 80%
  font-weight: 400
  @media only screen and (min-width: $XS)
    max-width: 540px
    font-size: 18px
    line-height: 150%
.bold
  font-weight: bold
.text-link
  color: $linkColor
  font-weight: bold
  text-decoration: none
  &:hover
    color: $primaryColor
a
  text-decoration: none
  color: inherit
  display: contents
.button
  border-radius: 120px
  background-color: #fff
  border: none
  font-weight: bold
  color: #000
  svg
    fill: #000
  &:hover
    color: $textWhite !important
    cursor: pointer
    background-color: $darkColor !important
    transition: all .3s ease-in-out
    svg
      fill: #fff
.button-unhover
  border-radius: 120px
  background-color: #fff
  border: none
  font-weight: bold
  color: #000
  svg
    fill: #000
  &:hover
    cursor: pointer
.highlight
  background-color: #fff
  &:hover
    color: $textHighlight !important
.change-header
  box-shadow: rgb(0 0 0 / 25%) 0px -18px 42px
  .highlight
    background-color: $textHighlight
    transition: all .3s ease-in-out
    &:hover
      color: $textHighlight !important
      background-color: $darkColor
.button-basic
  padding: 12px

.translateY-50
  transform: translateY(50%)
.transparent
  background: transparent !important
.part-welcome
  background: $bgWelcome
  position: relative
  width: 100%
  flex-grow: 1
  display: flex
  max-width: 100%
  button
    -webkit-box-align: center
    align-items: center
    -webkit-box-pack: center
    justify-content: center
    text-align: center
    padding: 8px 35px
    height: 56px
    border-radius: 40px
    font-size: 16px
    line-height: 24px
    margin-right: 10px
    margin-top: 32px
    width: 100%
  @media only screen and (min-width: $MD)
    height: calc(100vh + 30px)
    &::before
      position: absolute
      width: 60px
      height: 60px
      z-index: 9999999
      background-color: red
      display: block
      top: 200px
      left: 50px
    button
      width: auto
      margin-top: 42px

.part-welcome-action
  display: flex
  justify-content: center
  flex-direction: column
  a
    display: contents
  @media only screen and (min-width: $XS)
    flex-direction: row
.content-block
  width: 100%
  position: absolute
  left: 0
  bottom: 0
  right: 0
  display: block
  @media only screen and (min-width: $XS)
    width: 50%
    left: 0
    top: 0
    bottom: 0
    display: block

  // @media only screen and (min-width: $XS)
  //   height: calc(100vh)
  //   .part-welcome
  //     ::after
  //       width: 50%
  //       top: 0
  //       bottom: 0
  //       left: auto
  //       right: 0
  //       display: block
  //     button
  //       padding: 8px 35px
  //       height: 56px
  //       border-radius: 40px
  //       font-size: 16px
  //       line-height: 24px
  //       margin-right: 10px
  //       margin-top: 32px
.image-block
  width: 100%
  position: absolute
  left: 0
  bottom: 0
  right: 0
  display: block
  background: linear-gradient(217.84deg, rgb(255, 251, 31) 4.65%, rgb(92, 238, 156) 162.6%)
  height: 230px
  @media only screen and (min-width: $XS)
    left: auto
    position: absolute
    width: 50%
    bottom: 0
    right: 0
    top: 0
    display: block
.tag-slogan
  font-weight: 500
  font-size: 18px
  line-height: 100%
  color: rgb(162, 168, 183)
  background: rgb(238, 239, 242)
  border-radius: 15.5px
  padding: 7px 16px
  display: inline-flex
  font-weight: bold
.decoration
  background-color: $decorationColor
  color: $textWhite
.transparent
  background-color: transparent
.border-thin
  border: 1px solid #ccc
.container
  margin: auto
  width: 100%

  @media only screen and (min-width: $XS)
    max-width: 1200px
.bottom-block
  margin-top: 20px
  @media only screen and (min-width: $XS)
    margin-top: 40px
.bottom-block-content
  display: flex
  align-items: center
  justify-content: space-between
  flex-direction: column
  padding: 20px 0px
  @media only screen and (min-width: $SM)
    flex-direction: row
    padding: 70px 0px 32px 0px
.button-fix
  border-radius: 120px
  display: inline-flex
  -webkit-box-align: center
  align-items: center
  -webkit-box-pack: center
  justify-content: center
  text-align: center
  padding: 8px 24px
  height: 47px
  font-size: 16px
  line-height: 120%
.button-special
  background: $darkColor
  color: $textWhite
  margin-top: 10px
  width: 100%
  &:hover
    background: $decorationColor !important
    color: $textWhite !important
  @media only screen and (min-width: $SM)
    margin-top: 0px
    width: auto
.change-header
  background-color: white
  box-shadow: rgb(0 0 0 / 25%) 0px -18px 42px !important
  transition: all .3s ease-in-out
.header
  transition: all .3s ease-in-out
  box-shadow: none
.primary-content
  font-size: max(calc(100vw / 768 * 44),15px)
  line-height: 110%
  max-width: 90%
  margin: 0px auto
  margin-bottom: 16px
  text-align: center
  @media only screen and (min-width: $XS)
    line-height: 100%
    max-width: 80%
    margin: 42px auto
    font-size: max(calc(100vw / 768 * 78),15px)
  @media only screen and (min-width: $SM)
    font-size: 68px
    line-height: 110%
    max-width: 80%
    margin: 42px auto
.navbar-left
  display: flex
  align-items: center
  justify-content: space-between
  gap: 32px
  a
    display: none
  @media only screen and (min-width: $XS)
    a
      display: inline
.block-media
  display: flex
  align-items: center
  gap: 12px
  width: 100%
  flex-wrap: wrap
  justify-content: space-between
  button
    width: 45%
    max-width: 260px
  @media only screen and (min-width: $SM)
    width: auto
    button
      width: auto

.menu-select
  display: flex
  @media only screen and (min-width: $XS)
    display: none
.listMenu
  position: fixed
  background: rgb(255, 255, 255)
  transition: all 0.3s ease-in-out 0s
  left: 0
  right: 0
  top: 80px
  bottom: 0
  z-index: 5
  display: flex
  justify-content: space-between
  flex-direction: column
  div
    ul
      font-weight: bold
      pading: 0px 40px
      margin-top: 24px
      margin-bottom: 8px
  button
    margin: 24px 40px

.button-create
  display: flex
  -webkit-box-align: center
  align-items: center
  -webkit-box-pack: center
  justify-content: center
  text-align: center
  margin-top: 10px
  background: linear-gradient(259.09deg, rgb(92, 238, 156) 8.08%, rgb(255, 239, 33) 91.92%)
  font-weight: bold
  font-size: 16px
  line-height: 24px
.border-header
  width: 100%
  background-color: white
  height: 60px
  display: flex
  @media only screen and (min-width: $MD)
    position: absolute
    top: calc( 100vh )
    height: 80px

.information-reward
  background-color: $bgWelcome
  border-radius: 15px
  padding: 20px 0px
  @media only screen and (min-width: $MD)
    padding: 30px
.button-second
  background-color: $bgWelcome
  border: 1px solid $bgWelcome
.information-reward--header
  display: flex
  align-items: center
  gap: 16px
  margin-bottom: 20px
  .button-basic
    padding: 8px 16px
    width: 33%
  .active
    background-color: $decorationColor
    color: white
    border: 1px solid $decorationColor
  @media only screen and (min-width: $SM)
    .button-basic
      padding: 6px 16px
      width: auto
.information-reward--content
  display: flex
  gap: 20px
  flex-direction: column
  .information-reward--content--header
    width: 100%
    .text-description
      text-align: left
      font-size: 12px
  .information-reward--content--body
    width: 100%
    .text-description
      text-align: left
      font-size: 12px
  @media only screen and (min-width: $SM)
    flex-direction: row
    .information-reward--content--header
      height: 484px !important
      width: 25%
      min-width: 280px
    .information-reward--content--body
      width: 75%
      height: 100%
.tag-border
  background: rgb(255, 255, 255)
  border-radius: 20px
.information-reward--content--header.tag-border
  height: 100%
  box-sizing: border-box
  display: flex
  gap: 12px
  flex-direction: column
  -webkit-box-pack: justify
  padding: 30px
  justify-content: space-between
.information-reward--content--body
  flex-wrap: wrap
  display: flex
  gap: 20px
  justify-content: space-between
  .tag-border
    padding: 30px
    height: 100%
    box-sizing: border-box
    display: flex
    flex-wrap: wrap
    gap: 12px
    width: calc(100%)
    flex-direction: column
    -webkit-box-pack: justify
    justify-content: space-between
  @media only screen and (min-width: $SM)
    .tag-border
      width: calc(50% - 10px)
      height: 232px

h1
  margin: 0px
.spacing-decoration
  margin-bottom: 20px
  @media only screen and (min-width: $MD)
    margin-top: 50px

.marquee-container
  a
    svg
      margin: 20px
    img
      margin: 20px
      filter: grayscale(100%)
    &:hover
      cursor: pointer
      svg
        transition: all 0.3s ease-in-out 0s
        fill: #2762ff
      img
        transition: all 0.3s ease-in-out 0s
        filter: saturate(1)
  @media only screen and (min-width: $MD)
    a
      svg
        margin: auto
      img
        margin: auto

.tag-border-comunity
  background: rgb(25, 27, 32)

  color: rgb(255, 255, 255)

  display: none
  @media only screen and (min-width: $XS)
    padding: 20px
    display: block
  @media only screen and (min-width: $MD)
    padding: 50px 0px
    border-radius: 30px
.top-block
  padding: 20px 0px
  gap: 20px
  display: flex
  flex-wrap: wrap
  -webkit-box-pack: justify
  justify-content: space-between
  @media only screen and (min-width: $SM)
    padding: 50px 50px
.item-comunity
  width: calc(50% - 20px)
  background: rgb(39, 42, 50)
  border-radius: 24px
  position: relative
  padding: 40px 65px
  display: flex
  box-sizing: border-box
  flex-direction: column
  -webkit-box-pack: center
  justify-content: center
  -webkit-box-align: center
  align-items: center
  transition: all 0.3s ease-in-out 0s
  a
    position: absolute
    width: 100%
    height: 100%
    top: 0px
    left: 0px
    z-index: 10
    font-size: 0px
  &:hover
    cursor: pointer
  @media only screen and (min-width: $MD)
    width: calc(25% - 20px)

.telegram
  &:hover
    background-color: rgb(80, 161, 220)
.twitter
  &:hover
    background-color: rgb(29, 155, 240)
.medium
  &:hover
    background-color: rgb(255, 255, 255)
    color: black
    svg
      fill: black
.discord
  &:hover
    background-color: rgb(103, 115, 255)

.icon
  height: 60px
  svg
    fill: white
    transition: all 0.3s ease-in-out 0s
    width: 78px
    height: 68px
  @media only screen and (min-width: $SM)
    height: 120px

.title-icon
  margin-top: 40px
  font-weight: 700
  font-size: 32px
  line-height: 150%
  text-align: center
  text-transform: capitalize
  p
    margin-bot: 0px !important

@keyframes flash
  0% 
    opacity: 0
  49%
    opacity: 0
  51%
    opacity: 1
  100%
    opacity: 1