$primaryColor: #3a9037
$linkColor: #000
$textHighlight: rgb(92, 238, 156)
$darkColor: rgb(25, 27, 32)
$textWhite: #fff
$bgWelcome: rgb(246, 247, 248)
$decorationColor: #3a9037
$XS: 480px
$SM: 768px
$MD: 1024px

$play: running
$direction: normal
$duration: 230.1s
$delay: 0s
$iteration-count: infinite
